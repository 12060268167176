import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { randomId, sendToApi } from "../../utils/utilFunctions";
import { toastify } from "../addons/toast/Toast";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EuroIcon from "@material-ui/icons/Euro";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input } from "../addons/input/Input";
import PhoneInputool from "../addons/input/PhoneInputool";
import PromoCode from "../promocode/PromoCode";
import Modal from "./Modal";
import Modal2 from "./Modal2";
import "./sendmoney.css";
// import Widget from './Widget'
// const EUR = 655
// const EuroFees=655*0.964
const FEES = 0.0396;
// const INTOUCHFEES=250 XAF (0.38 EUR)

// mercuryo fee up to 3.8%

let widgetUrl = "https://mercuryo.ipercash.fr/";
// let widgetUrl = process.env.REACT_APP_MERCURYO_URL
// console.log("the widget url ", widgetUrl)

var interval = null;

function SendMoney({ amount, country, User }) {
  const { t } = useTranslation();
  let enable = process.env.REACT_APP_SEND_ENABLE;
  const minValue = process.env.REACT_APP_SEND_MIN;
  const maxValue = process.env.REACT_APP_SEND_MAX;

  const [currency, setCurrency] = useState("EUR");
  const [conversion, setConversion] = useState("655.77");
  const [convertedAmount, setConvertedAmount] = useState(25.161);
  const [rate, setRate] = useState();

  const validationSchema = yup.object({
    name: yup.string().required(`${t("formiksell3")}`),
    xaf: yup.number().required(`${t("formikSell3")}`),
    eu: yup.number().required(`${t("formikSell4")}`),
    EUR: yup.number().required("formikSell5"),
    amount: yup.number().required(`${t("formikSell5")}`),
    newAmount: yup.number().required(`${t("formikSell5")}`),
    phone: yup.string().required(`${t("formikSell6")}`),
    cfphone: yup.string().required(`${t("formikSell7")}`),
  });

  // value of differents field in the form
  const [state, setState] = useState({
    // amount: amount, name: "", phone: "", cPhone: "", fees: amount * FEES, newAmount: amount, EUR: "",
    amount: convertedAmount,
    eu: "",
    name: "",
    phone: "",
    cPhone: "",
    fees: 0.99,
    newAmount: convertedAmount,
    EUR: 655.77,
  });
  const [load, setLoad] = useState(false);
  // handling error on different field
  const [errors, setErrors] = useState({
    amount: false,
    eu: false,
    name: false,
    phone: false,
    cPhone: false,
  });
  // show waiting modal
  const [modal, setModal] = useState({
    open: false,
    closable: false,
    operationId: "IPercashid123456",
    status: "init",
  });
  // showing for show or not to taxations
  const [showing, setShowing] = useState(false);
  // promo state
  const [promo, setPromo] = useState({
    promotion: false,
    code: "",
    show: false,
  });

  const [mode, setMode] = useState(false);

  const [isoCountry, setIsoCountry] = useState("+237");

  //initialisation of formik validation
  const formik = useFormik({
    initialValues: {
      eu: convertedAmount,
      fees: 0.99,
      amount: Math.round(convertedAmount * conversion),
      phone: "",
      cPhone: "",
      EUR: conversion,
      newAmount: "",
      name: "",
    },
    validationSchema,
    onSubmit: async () => {},
  });

  useEffect(() => {
    setTimeout(() => {
      amountTaxation();
    }, 300);

    fetch(process.env.REACT_APP_API_URL + "currencies")
      .then((resp) => resp.json())
      .then((data) => {
        // console.log('les data', data.XAF)
        // setState({ ...state, EUR: data.XAF })
        formik.setValues({ ...formik.values, EUR: data.XAF });
      })
      .catch((err) => 0);
  }, []);

  useEffect(() => {
    amountTaxation();
  }, [formik.values.amount, formik.values.eu]);

  const cancelForm = (e) => {
    e.preventDefault();
    formik.setValues({
      amount: amount,
      eu: "",
      name: "",
      phone: "",
      cPhone: "",
      newAmount: amount,
    });
    // setState({ amount: amount,eu: "", name: "", phone: "", cPhone: "", newAmount: amount })
  };

  let history = useHistory();
  // handle change on different field, update mactching field in state
  const handleChange = (e) => {
    let newState = formik.values;
    newState[e.name] = e.value;
    formik.setValues({ ...formik.values });
    amountTaxation();
    if (formik.values.phone === formik.values.cPhone) {
      setShowing(!showing);
    }
    if (e.value != undefined) {
      setIsoCountry(e.value.slice(0, 4));
    }
  };
  const handleChange2 = (e) => {
    let newState = formik.values;
    newState[e.name] = e.value;
    formik.setValues({ ...formik.values });
    amountTaxation();
    if (formik.values.phone === formik.values.cPhone) {
      setShowing(!showing);
    }
    if (e.value != undefined) {
      setIsoCountry(e.value.slice(0, 4));
    }
  };
  // handle errors in field, update field in error
  const handleBlur = (e) => {
    if (e.value === "") {
      let newErrors = errors;
      newErrors[e.name] = true;
      setErrors({ ...errors });
    } else {
      let newErrors = errors;
      newErrors[e.name] = false;
      setErrors({ ...errors });
    }
  };

  // this function send the data operation on api, open the widget and show the modal
  const send = () => {
    setLoad(true);
    let params = {
      transaction_id: randomId("C"),
      phone: formik.values.phone,
      name: formik.values.name,
      userId: User.userId,
      /* fiat_pay: Math.floor(
        formik.values.EUR *
          (formik.values.newAmount + Math.round(formik.values.fees))
      ), */
      fiat_pay: parseInt(parseInt(formik.values.amount) + parseInt(conversion)),
      initial_amount: parseInt(formik.values.amount),
      promotion: promo.promotion ? true : null,
      code: promo.promotion ? promo.code : null,
      provider: process.env.REACT_APP_MOBILE_PROVIDER,
    };
    // console.log("le params", params)
    // TODO : make that page open after getting request response ( use await )
    // window.open(widgetUrl + '?d=' + randomChain() + ';' + (Math.ceil(parseFloat(state.amount) + parseFloat(state.fees))) * 0.579 + ';' + params.transaction_id, '_blank')
    sendToApi("send/init", params, User.token)
      .then((data) => {
        // console.log("the response ", data)
        if (data.success) {
          // console.log("we are in the if ")
          setModal({
            ...modal,
            open: true,
            closable: false,
            operationId: params.transaction_id,
            fiatCurrency: currency,
            status: "pending",
          });
          interval = setInterval(async () => {
            getStatus(params.transaction_id);
          }, 60 * 1000);
        } else toastify("error", `${formikSell13}`);
        setLoad(false);
      })
      .catch((err) => {
        closeModal();
        toastify("error", `${formikSell14}`);
        setLoad(false);
      });
  };
  // this get the status of operation at mercuryo
  const getStatus = (id) => {
    let message = { id: id, userId: User.userId };
    sendToApi("send/status", message, User.token)
      .then((data) => {
        console.log("les data de get status ", data);
        if (data.status === "completed") {
          clearInterval(interval);
          let params = {
            // ...state,
            ...formik.values,
            id: id,
            operation: "credit",
            amount: Math.round(formik.values.amount),
          };
          sessionStorage.setItem("data", JSON.stringify(params));
          history.push("/complete");
          return data;
        } else if (data.status === "cancelled") {
          // console.log("c'est cancelled")
          setModal({
            ...modal,
            open: true,
            closable: true,
            operationId: id,
            status: "failed",
          });
          clearInterval(interval);
        } else if (data.status === "error") {
          clearInterval(interval);
          setModal({
            ...modal,
            open: true,
            closable: true,
            operationId: id,
            status: "failed",
          });
        } else {
          /* may be should do something here */
        }
      })
      .catch((err) => {
        console.log("there is an error", err);
        clearInterval(interval);
        setModal({ ...modal, closable: true, status: "failed" });
        // window.location.reload()
      });
  };
  // this function handle disabled propertie of button
  const active = () => {
    console.log(minValue, Math.ceil(convertedAmount), maxValue);
    if (
      minValue <= Math.ceil(convertedAmount) &&
      Math.ceil(convertedAmount) <= maxValue &&
      formik.values.name &&
      formik.values.phone &&
      isValidPhoneNumber(formik.values.phone || 342) &&
      formik.values.phone === formik.values.cPhone
    ) {
      return false;
    } else return true;

    // (formik.values.amount >= minValue && formik.values.amount <= maxValue)
  };
  // this function check phone number
  const validPhone = (value, func) => {
    if (value) {
      return !func(value);
    }
    return false;
  };
  // this function check if the two number are same
  const checkConfirm = (value1, value2) => {
    if (value1) {
      return value1 !== value2;
    }
    return false;
  };
  const closeModal = () => {
    clearInterval(interval);
    formik.setValues({
      amount: amount,
      eu: "",
      name: "",
      phone: "",
      cPhone: "",
      newAmount: amount,
    });
    // setState({ amount: amount, eu: "", name: "", phone: "", cPhone: "", newAmount: amount })
    setModal({ open: false, closable: false });
    console.log("le close lancee");
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (enable === "FALSE") {
      return false;
    }
    send();
  };
  // la function du submit au boutton pour changer le taux
  // const eclips = () => {
  //     if (showing == true) {
  //         setShowing(!showing)

  //         // return false
  //     } else {

  //         if (enable == 'FALSE' || (country !== "CM" ) ) {
  //             setMode(!mode)
  //         } else {
  //             send()
  //         }

  //     }

  // }
  // console.log("la valeur max",maxValue, "la val minimùal", minValue)
  // la function qui gere le taxe sur le montant
  const amountTaxation = () => {
    // console.log("le amount")
    let fees, newAmount;
    // console.log("la valeur de base du formik amount", formik.values.amount)
    switch (true) {
      case formik.values.amount === "" || formik.values.eu === "":
        // console.log("the empty case ", formik.values.amount)
        fees = 0;
        newAmount = 0;
        // setState({ ...state, fees: 0, newAmount: 0 })
        formik.setValues({ ...formik.values, fees: 0, newAmount: 0 }, true);
        break;

      case minValue <= formik.values.eu:
        // console.log("the case ", formik.values.amount)
        fees = 0.99;
        newAmount = Number(formik.values.eu) + fees;
        formik.setValues({ ...formik.values, fees, newAmount }, true);
        // console.log("le newAmount", newAmount)
        break;
      case formik.values.eu <= maxValue:
        // console.log("the case ", formik.values.amount)
        fees = 0.99;
        newAmount = Number(formik.values.eu) + fees;
        formik.setValues({ ...formik.values, fees, newAmount }, true);
        // console.log("le newAmount", newAmount)
        break;

      default:
        // console.log("the default case ", formik.values.amount)
        fees = 0;
        newAmount = 0;
        // setState({ ...state, fees: 0, newAmount: 0 })
        formik.setValues({ ...formik.values, fees: 0, newAmount: 0 }, true);
        break;

      // case (state.amount <= 50):
      //     // console.log("the case ", state.amount)
      //     fees = Math.ceil(state.amount * 0.0396 + 0.38)
      //     newAmount = state.amount - fees
      //     setState({ ...state, fees, newAmount })
      //     break

      // case (51 <= state.amount && state.amount < 150):
      //     // console.log("the case ", state.amount)
      //     fees = 1.95
      //     newAmount = state.amount - fees
      //     setState({ ...state, fees, newAmount })
      //     break

      // case (151 <= state.amount && state.amount < minValue0):
      //     // console.log("the case ", state.amount)
      //     fees = 2.95
      //     newAmount = state.amount - fees
      //     setState({ ...state, fees, newAmount })
      //     break

      // case (minValue0 <= state.amount):
      //     // console.log("the last case ", state.amount)
      //     fees = 3.95
      //     newAmount = state.amount - fees
      //     setState({ ...state, fees, newAmount })
      //     break
    }
  };

  const activePromotion = (code, response) => {
    setPromo({ promotion: true, code: code, show: true });
    // console.log('les données de retour activePromotio',code,response)
    setState({ ...state, fees: 0 });
    formik.setValues({ ...formik.values, fees: 0 }, true);
  };

  const setPhone = (name, val) => {
    formik.setFieldValue(name, val);
  };
  (() => {
    const montant = parseFloat(formik.values.xaf);

    if (formik.values.phone && !formik.errors.phone) {
      !isValidPhoneNumber(formik.values.phone) &&
        formik.setFieldError("phone", `${t("formikBuy8")}`);
    }
    if (formik.values.cPhone && !formik.errors.cPhone) {
      formik.values.cPhone !== formik.values.phone &&
        formik.setFieldError("cPhone", `${t("formikBuy9")}`);
    }
    if (formik.values.xaf && !formik.errors.xaf) {
      minValue <= formik.values.eu &&
        formik.setFieldError(
          "xaf",
          `${
            t("formikBuy11") +
            min[formik.values.crypto] +
            " et " +
            max[formik.values.crypto] +
            " XAF."
          }`
        );
    }
    if (formik.values.xaf && !formik.errors.xaf) {
      formik.values.eu <= maxValue &&
        formik.setFieldError(
          "xaf",
          `${
            t("formikBuy11") +
            min[formik.values.crypto] +
            " et " +
            max[formik.values.crypto] +
            " XAF."
          }`
        );
    }
    // if (formik.values.wallet && !formik.errors.wallet) {
    //     !(WAValidator.validate(`${formik.values.wallet}`, `${formik.values.crypto}`) || WAValidator.validate(`${formik.values.wallet}`, `${formik.values.crypto}`, 'testnet'))
    //         && formik.setFieldError('wallet', `${t('formikBuy10') + formik.values.crypto + t('formikBuy12')}`)
    // !formik.values.wallet.match(regWallet) && formik.setFieldError('wallet', `${t('formikBuy10')}`)
    // }
  })();

  const setTouched = (field) => {
    if (!formik.touched[field]) formik.setFieldTouched(field, true);
    if (formik.values.field == "") {
      formik.setFieldError("true");
    }
  };

  // function that manage the change of amount on each field
  const handleMoney = (e) => {
    console.log("conversion", conversion);
    // let result
    // let unit = formik.values.fiat == 'EUR' ? forex.XAF : forex.XAF / forex.USD
    switch (e.target.name) {
      case "amount":
        let resultat = (e.target.value / conversion).toFixed(2);
        setConvertedAmount(resultat);
        formik.setValues(
          { ...formik.values, amount: e.target.value, eu: resultat },
          true
        );
        break;
      case "eu":
        let result = Math.floor(e.target.value * conversion);
        formik.setValues(
          { ...formik.values, eu: e.target.value, amount: result },
          true
        );
        break;
      default:
        break;
    }
  };

  // Manage currency

  const fetchExchangeRate = async (currency) => {
    try {
      const response = await fetch(
        `https://api.exchangeratesapi.net/v1/exchange-rates/latest?access_key=R80tRL9yYy81YIT7&base=EUR`
      );
      const data = await response.json();
      const rate = data.rates[currency];
      
      const convert = data.rates["XAF"] / data.rates[currency];
      setConvertedAmount((formik.values.amount / convert).toFixed(2));
      console.log("the convert", convert);
      setConversion(convert);
      // onConvert(amount, currency, convertedAmount);
      console.log("the rate ", data.rates, amount, rate);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
    }
  };

  const handleCurrency = (e) => {
    const currency = e.target.value;

    fetchExchangeRate(currency);

    setCurrency(e.target.value);

    console.log("le country pays", e.target.value);
  };

  return (
    <>
      {enable === "FALSE" ? (
        <h3 className="disjointe">{t("sendMoneySous16")}</h3>
      ) : (
        ""
      )}
      <div className="sendmoney">
        <Modal
          option={{
            ...modal,
            amount: promo.promotion
              ? convertedAmount
              : (Number(convertedAmount) + 1).toFixed(2),
          }}
          close={closeModal}
        />
        <Modal2 mode={mode} close={() => setMode(false)} />
        {!active() && (
          <PromoCode
            activePromotion={activePromotion}
            closePromo={() =>
              setPromo({ ...promo, show: false, code: "NO_CODE" })
            }
          />
        )}
        <form className="form">
          <div className="form-head">
            <div className="form-group mob">
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Quelle est votre devise ?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currency}
                  onChange={handleCurrency}
                  style={{ width: "320px" }}
                >
                  <MenuItem value="EUR">
                    <EuroIcon style={{ marginRight: 8 }} /> EUR
                  </MenuItem>
                  <MenuItem value="USD">
                    <AttachMoneyIcon style={{ marginRight: 8 }} /> USD
                  </MenuItem>
                  <MenuItem value="CAD">
                    <MonetizationOnIcon style={{ marginRight: 8 }} /> CAD
                  </MenuItem>
                  <MenuItem value="GBP">
                    <MonetizationOnIcon style={{ marginRight: 8 }} /> GBP
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="">
              1,OO {currency} <h3 className="sign">&cong;</h3> {Number(conversion).toFixed(2)} XAF
            </div>
          </div>

          <div className="form-head">
            <div className="form-group mob">
              <Input
                val={formik.values.amount}
                name="amount"
                label={t("sendMoneySous9")}
                type="number"
                help={t("sendMoneySous15")}
                error={
                  Math.floor(
                    formik.values.amount <
                      Math.floor(minValue * formik.values.EUR)
                  ) || formik.values.amount >= maxValue * formik.values.EUR
                }
                change={handleMoney}
                handBlur={() => setTouched("amount")}
              />
            </div>
          </div>
          <div className="form-head">
            <div className="form-groups">
              <Input
                val={convertedAmount}
                name="eu"
                label={t("buyCryptoCardSous5") + currency}
                type="number"
                help={t("sendMoneySous18")}
                error={formik.errors.eu && Boolean(formik.touched.eu)}
                change={handleMoney}
                handBlur={() => setTouched("eu")}
                disabled={true}
              />
            </div>
            <div className="">
              <div className="invisible">
                1,OO EUR <h3 className="sign">&cong;</h3> {formik.values.EUR}{" "}
                XAF
              </div>
            </div>
          </div>

          <h3 className="to-top"> {t("sendMoneyTitle")} </h3>
          <div className="form-body">
            <div className="form-group">
              <Input
                val={formik.values.name}
                name="name"
                label={t("sendMoneySous10")}
                error={errors.name}
                change={formik.handleChange}
                handBlur={handleBlur}
                help={t("sendMoneySous10")}
              />
            </div>
            <div className="form-group">
              <PhoneInputool
                val={formik.values.phone}
                label={t("sendMoneySous11")}
                name="phone"
                id="phone"
                help={t("sendMoneySous17")}
                change={handleChange}
                handBlur={() => setTouched("phone")}
                error={
                  validPhone(formik.values.phone, isValidPhoneNumber) &&
                  formik.errors
                }
                cm={true}
                alert={isoCountry !== "+237"}
                country={false}
                all={false}
              />
            </div>
            <div className="form-group">
              <PhoneInputool
                val={formik.values.cPhone}
                label={t("sendMoneySous13")}
                name="cPhone"
                id="cPhone"
                help={t("sendMoneySous14")}
                change={handleChange2}
                handBlur={() => setTouched("cPhone")}
                error={
                  checkConfirm(formik.values.cPhone, formik.values.phone) &&
                  formik.errors
                }
                cm={true}
                alert={isoCountry !== "+237"}
                country={false}
                all={false}
              />
            </div>
            {showing ? (
              <div className="warning delay">
                <h4>{t("sendMoneySous7")}</h4>
                <p>{t("sendMoneySous8")}</p>
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <button
                disabled={active()}
                onClick={load ? cancelForm : handleSubmit}
              >
                {load ? (
                  <center>
                    <ReactLoading
                      type="spin"
                      color="#ffffff"
                      width="28px"
                      height="28px"
                    />
                  </center>
                ) : (
                  t("sendMoneySous1")
                )}
              </button>
            </div>
          </div>
        </form>

        <div className="summary">
          <h2>{t("sendMoneySous2")}</h2>
          <div className="row">
            <span>{t("sendMoneySous3")}</span>
            <span>
              {" "}
              {Intl.NumberFormat("de-DE").format(convertedAmount)} {currency}{" "}
            </span>
          </div>
          <div className="row">
            <span>{t("sendMoneySous4")}</span>
            <span>
              {" "}
              {Intl.NumberFormat("de-DE").format(formik.values.fees)} {currency}{" "}
            </span>
            {/* <span> {Intl.NumberFormat('de-DE').format((formik.values.fees))}  EUR </span> */}
          </div>
          <div className="row">
            <span>{t("sendMoneySous5")} </span>
            <span>
              {" "}
              {Intl.NumberFormat("de-DE").format(
                parseFloat(convertedAmount) + parseFloat(formik.values.fees) ||
                  0
              )}{" "}
              {currency}{" "}
            </span>
          </div>
          <div className="row">
            <span>{t("sendMoneySous6")}</span>
            <span>
              {" "}
              {Intl.NumberFormat("de-DE").format(formik.values.amount)} XAF{" "}
            </span>
          </div>

          <h2>{t("sendText5")}</h2>
          <div className="warning eclips">
            <div className="row row1">
              <span>{t("sendText1")} </span>
              <span>
                {" "}
                {"0.99  "}
                {currency}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  amount: state.amountReducer.amount,
  country: state.countryReducer.country,
  User: state.userReducer.user,
});

export default connect(mapStateToProps)(SendMoney); //SendMoney

/* percentage is at 4% */
